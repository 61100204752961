import React, { useState } from 'react'

import {
  ButtonGroup,
  CardViewFillIcon,
  ListViewFillIcon,
  SquareButton
} from '@te-digi/styleguide'

const ButtonGroupExample1 = () => {
  const [selected, setSelected] = useState('card')

  return (
    <ButtonGroup>
      <SquareButton
        ariaCurrent={selected === 'card'}
        iconLeft={<CardViewFillIcon />}
        onClick={() => setSelected('card')}
        variant={selected === 'card' ? undefined : 'outline'}
      >
        Kortit
      </SquareButton>
      <SquareButton
        ariaCurrent={selected === 'list'}
        iconLeft={<ListViewFillIcon />}
        onClick={() => setSelected('list')}
        variant={selected === 'list' ? undefined : 'outline'}
      >
        Lista
      </SquareButton>
    </ButtonGroup>
  )
}

export { ButtonGroupExample1 }

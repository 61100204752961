import React from 'react'

import { ButtonGroup } from '@te-digi/styleguide'

import { ButtonGroupExample1 } from '../../examples/react/ButtonGroupExample1'
import { Content } from '../../components/Content'
import { Playground } from '../../components/Playground'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="ButtonGroup"
    components={[{ component: ButtonGroup }]}
  >
    <Section>
      <Playground example={ButtonGroupExample1} />
    </Section>
  </Content>
)

export default Page
